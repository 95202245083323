import React from 'react';
import { makeStyles} from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';

import Main from "./Mains";
import theme from './theme';
import {BrowserRouter} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
}));

function App() {
    const classes = useStyles();

    return (
            <ThemeProvider theme={theme}>
                <div
                    className={classes.root}
                    onContextMenu={(e)=>e.preventDefault()}
                >
                    <Main/>
                </div>
            </ThemeProvider>
    );
}

export default App;
