import { makeStyles} from '@mui/styles';
import {useNavigate} from "react-router-dom";
import 'intersection-observer'
import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import theme from "./theme";
import {Context} from "./Context";
import React, {memo, useContext, useEffect, useState} from 'react';
import withContext from "./withContext";

import {useLongPress} from 'use-long-press';

const useStyles = makeStyles({
    root: {
        display: 'grid',
    },

        imageContainer: {
            gridColumn: 1,
            gridRow: 1,
            width: '100%',
            height: '100%',
            position: 'relative',
        },

            avatar: {
                width: '100%',
                height: '100%',
                // to keep image ratio with size forcer => crop
                objectFit: 'cover',
                cursor: 'pointer',
            },

            miniProgressBar: {
                width: '25%',
                height: '25%',
                position: 'absolute',
                bottom: '8%',
                left: '8%',
                zIndex: 1
            },

        playIconContainer: {
            gridColumn: 1,
            gridRow: 1,
            width: '100%',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

            play: {
                color: 'white',
                //fontSize: 100,
            },

});

function AudioButton(props) {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        if(redirect) {
            setRedirect(false);
            navigate("/sounds/" + props.uuid, {replace: true})
        }
    }, [redirect]);

    const bind = useLongPress(
        () => {
            setRedirect(true);
            props.stopAudio();
        },
        {
            // onStart: event => console.log('Press started'),
            // onFinish: event => console.log('Long press finished'),
            // onCancel: event => console.log('Press cancelled'),
            // onMove: event => console.log('Detected mouse or touch movement'),
            filterEvents: event => true, // All events can potentially trigger long press (same as 'undefined')
            threshold: 500, // In milliseconds
            captureEvent: true, // Event won't get cleared after React finish processing it
            cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
            cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
            detect: 'pointer', // Default option
        }
    );

    return (
                // eslint-disable-next-line react-hooks/rules-of-hooks
                    <div
                        className={classes.root}
                        style={{ width: props.size, height: props.size }}
                        {...bind()}
                    >

                        <div
                            className={classes.imageContainer}
                            style={{ width: props.size, height: props.size }}

                        >

                        <img
                            src={props.image}
                            alt={props.label}
                            className={classes.avatar}
                            onClick={() => props.handler(props.uuid, props.sound)}
                        />


                        {props.currentSoundUuid === props.uuid && props.isPlaying &&
                            <CircularProgressbar
                                value={props.percentage}
                                className={classes.miniProgressBar}
                                strokeWidth={40}
                                styles={buildStyles({
                                    pathTransition:
                                        props.percentage === 0 ? "none" : "stroke-dashoffset 0.0s ease 0s",
                                    trailColor: theme.palette.primary.main,
                                    pathColor: 'black',
                                    strokeLinecap: "butt"
                                })}
                            />
                        }
                        </div>

                        {props.isSoundInfo && !props.isPlaying &&
                            <div
                                className={classes.playIconContainer}
                                onClick={() => props.handler(props.uuid, props.sound)}
                            >
                                <PlayCircleFilledTwoToneIcon
                                    className={classes.play}
                                    style={{width: '40%', height: '40%'}}
                                />
                            </div>
                        }

                    </div>


    )
}

const ContextSelector = () => {
    const {
        audioPlayer,
        currentSoundUuid,
        handler,
        setAudio,
        percentage,
        stopAudio,
        isPlaying
    }: Context = useContext(Context);
    return {
        audioPlayer,
        currentSoundUuid,
        handler,
        setAudio,
        percentage,
        stopAudio,
        isPlaying
    };
};

export default withContext(memo((AudioButton)), ContextSelector);
