//@Flow

import React, {memo, useContext, useEffect, useState} from 'react';
import { makeStyles} from '@mui/styles';
import AudioButton from "./AudioButton";
import {useLocation, useParams} from "react-router";
import {Context} from "./Context";
import withContext from "./withContext";
import LoopIcon from '@mui/icons-material/Loop';
import CloseIcon from '@mui/icons-material/Close';
import VideocamIcon from '@mui/icons-material/Videocam';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import {Link, useNavigate} from "react-router-dom";
import Chip from '@mui/material/Chip';
import {RWebShare} from "react-web-share";
import SendIcon from '@mui/icons-material/Send';
import {useSwipeable} from "react-swipeable";
import Sound from "./Sound";
import theme from "./theme";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        justifyContent: 'flex-start',
        alignContent: 'flex-start'
    },

    itemContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '30px',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        width: '100vW',

        // // remove scrollbar keep functionality
        // overflowY: 'auto',
        // '&::-webkit-scrollbar': {
        //     display: 'none'
        // },
        // '&': {
        //     'scrollbar-width': 'none',
        //     '-ms-overflow-style': 'none'
        // },
    },

    close: {
        color: 'white'
    },
    toto: {
        color: 'orange'
    },
    topics: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '5px',
        justifyContent: 'flex-start',
        alignContent: 'flex-start'
    },
    icons: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: '5px',
        justifyContent: 'flex-start',
        alignContent: 'flex-start'
    },
    chip: {
        fontWeight: 'bold'
    },
}));

const linkStyle = {
    backgroundColor: 'black',
};

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SoundInfos(props) {
    let navigate = useNavigate();

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            handleClose(); setUuidState(next.uuid);
            },
        onSwipedRight: () => {
            handleClose();
            setUuidState(previous.uuid)
        },
        onSwipedDown: () => { handleClose();quit()},
        onSwipedUp: () => { handleClose(); quit()},
    })


    let query = useQuery();
    let { uuid } = useParams();
    const uuidQuery = query.get("uuid");
    uuid = uuid ? uuid : uuidQuery;
    const [uuidState, setUuidState] = useState(uuid)

    const classes = useStyles();

    const item = props.sounds
        .find(s => uuidState === s.uuid);

    if(!item) return;
    const handleClose = () => {
        props.stopAudio();
        props.setRepeat(false);
    };


    const quit = () => {
        navigate("/sounds", {replace: true});
    }

    const onclick = (label: string) => {
       props.setActiveChip(label);
        props.setSearchState(undefined);
    };

    const previous = props.getPrevious(props.filteredData, uuidState);
    const next = props.getNext(props.filteredData, uuidState);

    const handleDownload = (url: string, fileName: string) => {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    return (
        <div className={classes.root}
            {...handlers}
        >
            <Link to={"/sounds/"}
                  onClick={() => {
                      handleClose();
                      quit();
                  }}
                  style={linkStyle}
            >
                <CloseIcon className={classes.close}/>
            </Link>

            <div className={classes.itemContainer}>
                <AudioButton
                    uuid={item.uuid}
                    label={item.label}
                    sound={item.sound}
                    image={item.image}
                    key={item.uuid}
                    size={props.onMobile ? '60vw' : '30vw'}
                    isSoundInfo={true}
                />
                <div className={classes.icons}>
                    <LoopIcon className={props.repeat ? classes.toto : classes.close}
                              onClick={() => props.setRepeat(!props.repeat)}
                    />

                    <RWebShare
                        data={{
                            url: encodeURI("https://app.otocards.com/sounds/" + item.uuid)
                        }}
                    >
                        <SendIcon className={classes.close}/>
                    </RWebShare>

                    {/*<div className={classes.icons}>*/}
                    {/*    <EqualizerIcon className={classes.close}*/}
                    {/*                  onClick={() => handleDownload(item.sound, `${item.label}.mp3`)}*/}

                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className={classes.icons}>*/}
                    {/*    <VideocamIcon className={classes.close}*/}
                    {/*                  onClick={() => handleDownload(item.video, `${item.label}.mp4`)}*/}

                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </div>

            <h1>{item.label}</h1>


            <div className={classes.topics}>
                {item.tags
                    .filter(topic => topic !== undefined && topic !== '')
                    .map(topic => {
                        return (

                            <Link
                                key={topic}
                                style={{textDecoration: 'none'}}
                                to={"/sounds/"}
                            >
                                <Chip
                                    key={topic}
                                    color={'primary'}
                                    label={topic} className={classes.chip}
                                    onClick={() => onclick(topic)}
                                />
                            </Link>
                        )
                    })}
            </div>

        </div>
    );
}

const ContextSelector = () => {
    const {
        audioPlayer,
        duration,
        sounds,
        filteredData,
        setRoute,
        searchState,
        setSearchOpened,
        activeChip,
        setActiveChip,
        setSearchState,
        repeat,
        setRepeat,
        getPrevious,
        getNext,
        stopAudio,
        onMobile,
    }: Context = useContext(Context);
    return {
        audioPlayer,
        duration,
        sounds,
        filteredData,
        setRoute,
        searchState,
        setSearchOpened,
        activeChip,
        setActiveChip,
        setSearchState,
        repeat,
        setRepeat,
        getPrevious,
        getNext,
        stopAudio,
        onMobile,
    };
};

export default withContext(memo((SoundInfos)), ContextSelector);
