import React from 'react';
import ReactDOM from 'react-dom/client';
import './fresh/index.css';
import reportWebVitals from './fresh/reportWebVitals';
import App from "./App";


const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.style.webkitTouchCallout='none';

// .prevent-select {
//     -webkit-user-select: none; /* Safari */
//     -ms-user-select: none; /* IE 10 and IE 11 */
//     user-select: none; /* Standard syntax */
// }
document.body.style.webkitUserSelect='none';
document.body.style.userSelect='none';
document.body.style.msUserSelect='none';

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
