import { createTheme,  } from '@mui/material/styles';

import {blue, blueGrey, grey, yellow} from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: blue,
        secondary: grey,
    },
});

export default theme;