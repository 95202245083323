import React, {memo, useContext, useEffect} from "react";
import {Context} from "./Context";
import withContext from "./withContext";
import {useParams} from "react-router";
import AudioButton from "./AudioButton";


function Player(props) {
    let { uuid } = useParams();
    const [item, setItem] = React.useState(undefined);
    const [open, setOpen] = React.useState(false);


    useEffect(() => {
        if (!open && uuid && props.sounds.length !== 0) {
            let itemFound = props.sounds.find(s => uuid === s.uuid)
            setItem(itemFound);
            props.setSound(itemFound.sound);
            setOpen(true);
        }
    });

    if (!item) {
        return;
    }

    const stop = () => {
        props.stopAudio();
        props.setSound(item.sound);
    }

    return (
        <>
            <div>{uuid} {item.label}</div>
            <div>{item.sound}</div>
            <button onClick={() => props.playAudio()}>play</button>
            <button onClick={() => props.pauseAudio()}>pause</button>
            <button onClick={() => stop()}>stop</button>
        </>
    )
}

const ContextSelector = () => {
    const {
        sounds,
        filteredData,
        setRoute,
        searchState,
        setSearchOpened,
        activeChip,
        setActiveChip,
        setSearchState,
        repeat,
        setRepeat,
        stopAudio,
        setSound,
        playAudio,
        pauseAudio,
        setAudio,
        duration,
        infoSize,
        onMobile,
        handler
    }: Context = useContext(Context);
    return {
        sounds,
        filteredData,
        setRoute,
        searchState,
        setSearchOpened,
        activeChip,
        setActiveChip,
        setSearchState,
        repeat,
        setRepeat,
        playAudio,
        pauseAudio,
        stopAudio,
        setSound,
        setAudio,
        duration,
        infoSize,
        onMobile,
        handler
    };
};

export default withContext(memo((Player)), ContextSelector);