import React, {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';

import { makeStyles} from '@mui/styles';


import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Sounds from "./Sounds";
import ReactAudioPlayer from "react-audio-player";
import {Context} from "./Context";
import removeAccents from "remove-accents";
import SoundInfos from "./SoundInfos";
import Player from "./Player";
import Infinite from "./Infinite";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100vH',
        flexGrow: '1',
    },
}));

const router = createBrowserRouter([
    // {
    //     path: "/soundInfos/:uuid",
    //     element: <SoundInfos/>
    // },
    {
        path: "/sounds/:uuid",
        element: <SoundInfos/>
    },
    // {
    //     path: "/topics/:topic",
    //     element: <Infinite/>
    // },
    {
        path: "/infinite",
        element: <Infinite/>
    },
    {
        path: "/sounds",
        element: <Infinite/>
    },
    {
        path: "/player/:uuid",
        element: <Player/>
    },
    {
        path: "/",
        element: <Infinite/>
    },
]);

function Main() {
    const classes = useStyles();
    const [sounds, setSounds] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [loadedData, setLoadedData] = useState([]);
    const [route, setRoute] = useState(undefined);
    const [size, setSize] = useState('33vw');
    const [infoSize, setInfoSize] = useState('50vw');
    const [audioPlayer, setAudioPlayer] = useState(undefined);
    const [currentSoundUuid, setCurrentSoundUuid] = useState(undefined);
    const [percentage, setPercentage] = useState(0);
    const [searchState, setSearchState] = useState(undefined);
    const [activeChip, setActiveChip] = useState(undefined);
    const [searchOpened, setSearchOpened] = useState(false);
    const [repeat, setRepeat] = useState(false);
    const [duration, setDuration] = useState(undefined);
    const [onMobile, setOnMobile] = useState(isMobile);
    const [searchLoaded, setSearchLoaded] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [init, setInit] = useState(false);
    const [refresh, setRefresh] = useState(false);


    const handler = (uuid: string, sound: string) => {
        if (currentSoundUuid === uuid) {
            stopAudio();
        } else {
            setAudio(uuid, sound);
            playAudio();
        }
    };

    const setSound = (sound: string) => {
        audioPlayer.audioEl.current.src = sound;
    }

    const setAudio = (uuid: string, sound: string) => {
        setCurrentSoundUuid(uuid);
        setPercentage(0);
        audioPlayer.audioEl.current.src = sound;
        audioPlayer.audioEl.current.loop = repeat;
    }

    const computeDuration = () => {
        var minutes = "0" + parseInt(audioPlayer.audioEl.current.duration / 60, 10);
        var seconds = "0" + parseInt(audioPlayer.audioEl.current.duration % 60);
        setDuration(minutes + ":" + seconds.slice(-2))
    }

    const playAudio = () => {
        setIsPlaying(true);
        audioPlayer.audioEl.current.play();
        setInterval(function () {
            if (audioPlayer.audioEl.current) {
                setPercentage(Math.trunc(audioPlayer.audioEl.current.currentTime / audioPlayer.audioEl.current.duration * 100));
             //   console.log('setInterval loop')console.log('setInterval loop')
            }
        }, 150);

    };

    const pauseAudio = () => {
        audioPlayer.audioEl.current.pause();
    }

    const stopAudio = () => {
        setIsPlaying(false);
        setCurrentSoundUuid(undefined);
        audioPlayer.audioEl.current.src = undefined;
        audioPlayer.audioEl.current.pause();
        audioPlayer.audioEl.current.currentTime = 0;
        audioPlayer.audioEl.current.loop = false;
        setPercentage(0);
    };

    const onEnded = () => {
        setIsPlaying(false);
        setCurrentSoundUuid(undefined);
        setPercentage(0);
    };

    const zoomIn = () => {
        if (size === 5)
            setSize(10)
        if (size === 10)
            setSize(25)
        if (size === 25)
            setSize(33)
        if (size === 33)
            setSize(50)
    }

    const zoomOut = () => {
        if (size === 50)
            setSize(33)
        else if (size === 33)
            setSize(25)
        else if (size === 25)
            setSize(10)
        else if (size === 10)
            setSize(5)
    }

    const cleanWord = (word: String) => removeAccents(word).toLowerCase();

    const isVisible = (label: string, tags: []) => {
        if (searchState === undefined || searchState.length < 2 || searchState === '' || searchState === ' ') {
            return true;
        }
        const cleanSearch = cleanWord(searchState);
        if (cleanWord(label).includes(cleanSearch)) {
            return true;
        }
        return tags && tags.some((tag) => cleanWord(tag).includes(cleanSearch));
    };

    const IsMatchTopic = (activeChip: string, tags: []) => {
        if (!activeChip) {
            return true;
        }
        return tags && tags.some((tag) => cleanWord(tag).includes(cleanWord(activeChip)));
    };

    const IsFiltered = (label: string, tags: [], topic: string) => {
        const visible = isVisible(label, tags);
        const matchTopic = IsMatchTopic(topic, tags);
        return visible && matchTopic;
    }

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }


    const fetchData = async () => {
        if(!init) {
         //   fetch('https://app.otocards.com/playlist.json')
            fetch('../playlist.json')
                .then((res) => res.json())
                .then((data) => {
                    const dataLoaded  = data.sounds;
                    shuffleArray(dataLoaded);
                    setSounds(dataLoaded);
                });
            setInit(true);
        }
    };


    const getNext = (sounds: [], uuid: String) => {
        let soundIndex = undefined;
        sounds.map((sound, index) => {
            if (sound.uuid === uuid) {
                soundIndex = index;
            }
        });

        const soundFound =  sounds[soundIndex + 1];
        if (!soundFound) {
            return sounds[0];
        }
        return soundFound;
    }

    const getPrevious = (sounds: [], uuid: String) => {
        let soundIndex = undefined;
        sounds.map((sound, index) => {
            if (sound.uuid === uuid) {
                soundIndex = index;
            }
        });

        const soundFound =  sounds[soundIndex - 1];
        if (!soundFound) {
            return sounds[sounds.length - 1];
        }
        return soundFound;
    }

    // first load
    useEffect(() => {
        setOnMobile(isMobile);
        setInit(true);
        fetchData()
    }, [init]);


    // when data is ok async
    useEffect(() => {
        setRepeat(repeat);
        if(audioPlayer?.audioEl)
        audioPlayer.audioEl.current.loop = repeat;
    }, [repeat]);

    // when searchState changed
    useEffect(() => {
        setFilteredData(sounds.filter(sound => IsFiltered(sound.label, sound.tags, activeChip)));
        if (sounds.find(sound => sound.uuid === currentSoundUuid)) {
            stopAudio();
        }
    }, [sounds, searchState, activeChip, refresh]);

    return (

        <Context.Provider
            value={{
                audioPlayer,
                currentSoundUuid,
                setCurrentSoundUuid,
                percentage,
                handler,
                setSound,
                setAudio,
                playAudio,
                pauseAudio,
                stopAudio,
                zoomIn,
                zoomOut,
                size,
                infoSize,
                sounds,
                filteredData,
                loadedData,
                setLoadedData,
                setRoute,
                searchOpened,
                setSearchOpened,
                searchState,
                setSearchState,
                activeChip,
                setActiveChip,
                repeat,
                setRepeat,
                duration,
                onMobile,
                searchLoaded,
                setSearchLoaded,
                isPlaying,
                refresh,
                setRefresh,
                getPrevious,
                getNext,
            }}
        >
            <div className={classes.root} onScroll={()=> console.log('onscroll')}>
                <ReactAudioPlayer
                    className="audio-element"
                    ref={(element) => setAudioPlayer(element)}
                    onEnded={() => onEnded()}
                    preload='metadata'
                    onLoadedMetadata={() => computeDuration()}
                />

                <RouterProvider router={router} />
            </div>
        </Context.Provider>
    );
}

export default Main;
