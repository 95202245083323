//@Flow

import React, {memo, useContext, useEffect, useState} from 'react';
import { makeStyles} from '@mui/styles';
import AudioButton from "./AudioButton";
import {useLocation, useParams} from "react-router";
import {Context} from "./Context";
import withContext from "./withContext";
import SearchBar from "./SearchBar";
import Topics from "./Topics";
import Sound from "./Sound";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    header: {
        color: 'black',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 5,
    },

    itemContainer: {
        flexGrow: 1,
        height: 0,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'start',
        width: '100vW',
        background: 'black',

        // remove scrollbar keep functionality
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '&': {
            'scrollbar-width': 'none',
            '-ms-overflow-style': 'none'
        },
    },
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Sounds(props) {
    const {topic} = useParams();
    let query = useQuery();
    let { uuid } = useParams();
    const uuidQuery = query.get("uuid");
    const searchParam = query.get("search");
    uuid = uuid ? uuid : uuidQuery;


    useEffect(() => {

        if (!props.searchLoaded) {
            searchParam && props.setSearchState(searchParam);
            props.setSearchLoaded(true);
        }
        topic && props.setActiveChip(topic);
        const topicParam = query.get("topic");
        if (topicParam !== undefined && topicParam !== null && topicParam !== '' ) {
            props.setActiveChip(topicParam);
        }
    }, []);


    const classes = useStyles();

    return (
        <div className={classes.root}>
            {uuid && <Sound uuid={uuid} />}
            <SearchBar/>
            <Topics activeChip={props.activeChip} setActiveChip={props.setActiveChip}/>
            <div className={classes.itemContainer}>
                {
                    props.filteredData.map(item => {
                        const {label, image, sound, uuid} = item;
                        return (
                            <AudioButton
                                uuid={uuid}
                                label={label}
                                sound={sound}
                                image={image}
                                key={uuid}
                                visible={true}
                                showInfoButton
                                size={props.size}
                            />
                        )
                    })
                }
            </div>
        </div>
    );
}

const ContextSelector = () => {
    const {
        sounds,
        filteredData,
        setRoute,
        searchState,
        searchOpened,
        activeChip,
        setActiveChip,
        setSearchState,
        size,
        searchLoaded,
        setSearchLoaded,
        stopAudio,
    }: Context = useContext(Context);
    return {
        sounds,
        filteredData,
        setRoute,
        searchState,
        searchOpened,
        activeChip,
        setActiveChip,
        setSearchState,
        size,
        searchLoaded,
        setSearchLoaded,
        stopAudio,
    };
};

export default withContext(memo((Sounds)), ContextSelector);
