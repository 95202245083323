//@Flow

import React, {memo, useContext, useEffect, useState} from 'react';
import { makeStyles} from '@mui/styles';
import AudioButton from './AudioButton';
import {useLocation, useParams} from "react-router";
import {Context} from "./Context";
import withContext from "./withContext";
import SearchBar from "./SearchBar";
import Topics from "./Topics";
import Sound from "./Sound";
import InfiniteScroll from "react-infinite-scroll-component";
import {Adsense} from "@ctrl/react-adsense";



const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    header: {
        color: 'black',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 5,
    },

    line: {
      display: 'flex',
    },

    itemContainer: {
        flexGrow: 1,
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'start',
        width: '100vW',
        background: 'black',
        // // remove scrollbar keep functionality
         overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '&': {
            'scrollbar-width': 'none',
            '-ms-overflow-style': 'none'
        },
    },
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Infinite(props) { const {topic} = useParams();
    let query = useQuery();
    let { uuid } = useParams();
    const uuidQuery = query.get("uuid");
    const searchParam = query.get("search");
    uuid = uuid ? uuid : uuidQuery;

    const [offset, setOffset] = useState(0);
    const [loadedData, setLoadedData] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const offsetDelta = 30;
    let offsetLocal = 0;

    let hasMoreLocal = true;

    useEffect(() => {
        if (!props.searchLoaded) {
            searchParam && props.setSearchState(searchParam);
            props.setSearchLoaded(true);
        }
        topic && props.setActiveChip(topic);
        const topicParam = query.get("topic");
        if (topicParam !== undefined && topicParam !== null && topicParam !== '' ) {
            props.setActiveChip(topicParam);
        }
    }, []);


    useEffect(() => {
        reset();
        initData();
    }, [props.filteredData]);

    const reset = () => {
        setOffset(0);
        setHasMore(true);
    }

    const initData = () => {
        setLoadedData(props.filteredData.slice(0,offsetDelta));
        setOffset(offsetDelta);
    }
    const fetchMoreData = () => {
        if (props.filteredData.length === 0) {
            return;
        }

        if (offsetLocal !== 0 && loadedData.length >= props.filteredData.length) {
            setHasMore(false);
            hasMore(false);
            return;
        }

        setTimeout(() => {
            setLoadedData(loadedData.concat(props.filteredData.slice(offset, offset + offsetDelta)));
            setOffset(offset+offsetDelta);
        }, 100);
    };

    const classes = useStyles();
    let index = -1;

    return (
        <div className={classes.root}>
            {uuid && <Sound uuid={uuid} />}
            <SearchBar/>
            <Topics activeChip={props.activeChip} setActiveChip={props.setActiveChip}/>

            <div className={classes.itemContainer} id="scrollableDiv" >
            {/*https://www.npmjs.com/package/react-infinite-scroll-component*/}
            <InfiniteScroll
                dataLength={loadedData.length}
                next={() => fetchMoreData()}
                hasMore={hasMoreLocal}
                //loader={<h4>Loading...</h4>}
                // endMessage={
                //     <p style={{textAlign: "center"}}>
                //         <b>Yay! You have seen it all</b>
                //     </p>
                // }
                scrollableTarget="scrollableDiv"
                // below props only if you need pull down functionality
                // refreshFunction={() => props.setRefresh(true)}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //     <h3 style={{ textAlign: 'center', color: 'white' }}>&#8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //     <CircularProgress color="secondary" />
                // }
            >
                {loadedData.reduce((r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r, [])
                    .map((itemsLine, lineNumber) => (
                    <div className={classes.line} key={lineNumber}>
                        {itemsLine.map((item) => (
                            <AudioButton
                                key={index++}
                                uuid={item.uuid}
                                label={item.label}
                                sound={item.sound}
                                image={item.image}
                                size={props.size}
                            />
                        ))}
                    </div>

                ))}
            </InfiniteScroll>
            <Adsense
                client="ca-pub-6840237114448946"
                slot="4776349459"
                style={{ width: 500, height: 300 }}
                format=""
            />
            </div>
        </div>
    );

}

const ContextSelector = () => {
    const {
        sounds,
        filteredData,
        setRoute,
        searchState,
        searchOpened,
        activeChip,
        setActiveChip,
        setSearchState,
        size,
        searchLoaded,
        setSearchLoaded,
        stopAudio,
        refresh,
        setRefresh,
    }: Context = useContext(Context);
    return {
        sounds,
        filteredData,
        setRoute,
        searchState,
        searchOpened,
        activeChip,
        setActiveChip,
        setSearchState,
        size,
        searchLoaded,
        setSearchLoaded,
        stopAudio,
        refresh,
        setRefresh,
    };
};

export default withContext(memo((Infinite)), ContextSelector);
