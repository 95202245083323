//@Flow

import {makeStyles} from '@mui/styles';
import Chip from '@mui/material/Chip';
import React, {memo, useContext, useState} from 'react';
import {Context} from "./Context";
import withContext from "./withContext";
import {Link} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

import theme from "./theme";

const useStyles = makeStyles(theme => ({
    topics: {
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        paddingLeft: 15,
        padding: '5px 0px',
        // remove scrollbar keep functionality
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '&': {
            'scrollbar-width': 'none',
            '-ms-overflow-style': 'none'
        },
    },
    chip: {
        color: 'white',
       // marginRight: 2,
        fontWeight: 'bold',
    },
}));

function Topics(Props) {
    const classes = useStyles();

    const {activeChip, setActiveChip} = Props;

    const onclick = (label: string) => {
        if (isActive(label)) {
            setActiveChip(undefined);
        } else {
            setActiveChip(label);
        }
    };

    const isActive = (chipLabel: string) => {
        return activeChip && activeChip === chipLabel;
    };

    const topicsList = ['Animé', "Film", 'Street Fighter', 'Générique', 'Star Wars', 'TV', 'Nintendo', "Série", 'Politique', 'Capcom', 'Jeu Vidéo'];

    topicsList.find(t => activeChip === t) === undefined && topicsList.push(activeChip);

    return (
        <div className={classes.topics}>

            {topicsList
                .filter(topic => topic !== undefined && topic !== '' )
                .map(topic => {
                return topic && (

                    <Link
                        key={topic}
                        style={{textDecoration: 'none'}}
                        to={"/"}
                    >
                            <Chip
                                key={topic}
                                label={topic}
                                className={classes.chip}
                                onClick={() => onclick(topic)}
                                style={{color: 'white', backgroundColor: isActive(topic) ? theme.palette.primary.main : theme.palette.secondary[800]}}
                            />
                    </Link>

            )
            })}
        </div>
    );
}

const ContextSelector = () => {
    const {
        currentSoundUuid,
        handler,
        percentage,
        size,
        stopAudio,
        zoomIn,
        zoomOut,
        setRoute,
    }: Context = useContext(Context);
    return {
        currentSoundUuid,
        handler,
        percentage,
        size,
        stopAudio,
        zoomIn,
        zoomOut,
        setRoute,
    };
};

export default withContext(memo((Topics)), ContextSelector);
