import React from "react";

export const Context = React.createContext({
    audioPlayer: '',
    currentSoundUuid: '',
    volume: 1,
    percentage: 0,
    handler:  () => {},
    setAudio:() => {},
    stopAudio: () => {},
    zoomIn: () => {},
    zoomOut: () => {},
    sounds: undefined,
    filteredData: undefined,
    searchState: '',
    setSearchState:  () => {},
    activeChip: '',
    setActiveChip: () => {},
    setRoute: () => {},
    searchOpened: false,
    setSearchOpened: () => {},
    repeat: false,
    setRepeat: () => {},
    duration: undefined,
});