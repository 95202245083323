import React, {memo, useContext} from 'react';
import {makeStyles} from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import withContext from "./withContext";
import {Context} from "./Context";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100vW',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "left",
        paddingTop: '5px',
        backgroundColor: 'black',
    },
    input: {
        color: 'black',
        backgroundColor: 'white',
        width: '100vW',
        fontWeight: 'bold',
    }
}));

function SeachBar(props) {
    const {searchState, setSearchState} = props;
    const classes = useStyles();

    const onchange = (value: string) => {
        setSearchState(value);
    };

    const onClear = () => {
        setSearchState('');
    };

    return (

            <div className={classes.root}>
                <TextField
                    className={classes.input}
                    id="outlined"
                    variant="outlined"
                    onChange={(event: object) => onchange(event.target.value)}
                    value={searchState}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchState ? <ClearIcon onClick={() => onClear()}/> : <SearchIcon/>}
                            </InputAdornment>
                        ),
                    }}
                />

            </div>
    );
}

const ContextSelector = () => {
    const {
        data,
        setRoute,
        searchOpened,
        setSearchOpened,
        searchState,
        setSearchState,
        activeChip,
        setActiveChip
    }: Context = useContext(Context);
    return {
        data,
        setRoute,
        searchOpened,
        setSearchOpened,
        searchState,
        setSearchState,
        activeChip,
        setActiveChip,
    };
};

export default withContext(memo((SeachBar)), ContextSelector);